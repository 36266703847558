/* 
Countdown timer (React, styled-components, try Typescript)
https://codepen.io/kamtr0n/pen/oNYrrGO
 */
/*
--color-bg: #22262E;
--color-heading: #798EB0;
--color-counter: #8973FD;
--font-family-heading: "Mukta";
*/

.WrapperStyled {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  @media (min-width: 35.5em) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0 2rem;
  }
}

.CounterStyled {
  // background: rgba(255, 255, 255, 0.025);
  border-radius: 1rem;
  // color: var(--color-counter);
  display: flex;
  flex-direction: column;
  // font-family: "JetBrains Mono", mono;
  font-size: clamp(1rem, 5vw, 99rem);
  line-height: 1;
  padding: 1.5vw;
  text-align: center;
  
  background: var(--color-green);
  color: var(--color-white);
  // font-family: sans-serif, mono;
  font-weight: bold;
  h2 {
    // color: var(--color-heading);
    // font-family: var(--font-family-heading);
    font-size: clamp(1rem, 2vw, 99rem);
    // font-weight: 300;
    letter-spacing: .1875em;
    margin: 1.25rem 0 0;
    order: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    
    color: var(--color-white-70);
    // font-family: sans-serif, mono;
  }
}