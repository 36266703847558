.Button_green, 
.Button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 0.875rem;
  margin: 0;
  outline: 0;
  border-radius: 0.375rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
  }
}

.Button {
  // default base-ui
  border: 1px solid var(--color-gray-200);
  // background-color: var(--color-gray-50);
  // color: var(--color-gray-900);
  
  //P07
  background: var(--color-brown);
  color: var(--color-white);
  
  @media (hover: hover) {
    &:hover {
      // background-color: var(--color-gray-100);
      background-color: var(--color-brown-light);
    }
  }
  &:active {
    background-color: var(--color-gray-100);
  }
  &:disabled {
    color: var(--color-gray-400);
    background-color: var(--color-gray-100);
  }
  &:focus-visible {
    outline: 2px solid var(--color-blue);
  }
}

.Button_green {
  border: 1px solid var(--color-gray-200);
  // background-color: var(--color-gray-50);
  // color: var(--color-gray-900);
  
  background: var(--color-green);
  color: var(--color-white);
  
  @media (hover: hover) {
    &:hover {
      // background-color: var(--color-gray-100);
      background-color: var(--color-green-light);
    }
  }
  &:active {
    background-color: var(--color-gray-100);
  }
  &:disabled {
    color: var(--color-gray-400);
    background-color: var(--color-gray-100);
  }
  &:focus-visible {
    outline: 2px solid var(--color-blue);
  }
}

.buttonText{
  color: var(--color-white);
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center; 
}