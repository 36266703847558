.App {
  /* background: #f00; */
  text-align: center;
  /* background-color: #F1F3E8; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BG {
  z-index: -1;
  position: fixed;
  top: 0;
  margin: 0;
  padding: 0;

  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: 100% 100%;
  
  min-height: 100%;
  max-width: 480px;
}

.layout {
  /* background: #0f0; */
  width: 100%;
  position: absolute;
  top: 0;
  margin: 0;
  
  min-height: 100%;
  max-width: 480px;
}
