:root {
  --color-brown-light: rgba(118, 96, 69, 1) ;
  --color-brown: rgba(94, 75, 54, 1);
  /* --color-brown-dark: rgba(70, 56, 40, 1); */
  
  --color-second-brown: rgba(150, 75, 0, 1);
  
  --color-green-light:  rgba(130, 145, 136, 1);
  --color-green: rgba(108, 123, 114, 1);
  
  --color-white: rgba(243, 232, 220, 1);
  --color-white-50: rgba(243, 232, 220, 0.5);
  --color-white-70: rgba(243, 232, 220, 0.7);
  
  /* v for Base-UI */
  --color-blue: oklch(45% 50% 264);
  --color-red: oklch(50% 55% 31);

  --color-gray-50: oklch(98.42% 0.0034 247.86);
  --color-gray-100: oklch(12% 9.5% 264 / 5%);
  --color-gray-200: oklch(12% 9% 264 / 8%);
  --color-gray-300: oklch(12% 8.5% 264 / 17%);
  --color-gray-400: oklch(12% 8% 264 / 38%);
  --color-gray-500: oklch(12% 7.5% 264 / 50%);
  --color-gray-600: oklch(12% 7% 264 / 67%);
  --color-gray-700: oklch(12% 6% 264 / 77%);
  --color-gray-800: oklch(12% 5% 264 / 85%);
  --color-gray-900: oklch(12% 5% 264 / 90%);
  --color-gray-950: oklch(12% 5% 264 / 95%);


  @media (prefers-color-scheme: dark) {
    --color-blue: oklch(69% 50% 264);
    --color-red: oklch(80% 55% 31);

    --color-gray-50: oklch(17% 1% 264);
    --color-gray-100: oklch(28% 3% 264 / 65%);
    --color-gray-200: oklch(31% 3% 264 / 80%);
    --color-gray-300: oklch(35% 3% 264 / 80%);
    --color-gray-400: oklch(47% 3.5% 264 / 80%);
    --color-gray-500: oklch(64% 4% 264 / 80%);
    --color-gray-600: oklch(82% 4% 264 / 80%);
    --color-gray-700: oklch(92% 4.5% 264 / 80%);
    --color-gray-800: oklch(93% 3.5% 264 / 85%);
    --color-gray-900: oklch(95% 2% 264 / 90%);
    --color-gray-950: oklch(94% 1.5% 264 / 95%);
  }
}

@font-face {
  font-family: 'Ma Shan Zheng';
  src: local(MaShanZheng-Regular),
    url(/assets/font/MaShanZheng-Regular.ttf) format("truetype");
    /* url('https://fonts.googleapis.com/css?family=Ma Shan Zheng'); */
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'LXGWWenKaiTC-Regular';
  src: url(/assets/font/LXGWWenKaiTC-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LXGWWenKaiTC-Bold';
  src: url(/assets/font/LXGWWenKaiTC-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LXGWWenKaiTC-Light';
  src: url(/assets/font/LXGWWenKaiTC-Light.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}