@import 'assets/css/theme.css';



* {
  font-family: 'LXGWWenKaiTC-Bold', sans-serif;
}

$breakpoints: (
  xs: 0px,
  sm: 480px,
);

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @error "No value found for #{$breakpoint} in $breakpoints map.";
  }
}

/* for rem */
html {
  font-size: 22px;
  @include breakpoint(sm) {
    font-size: 16px;
  }
}
/* Fonts */
.heading1{
    font-family: 'LXGWWenKaiTC-Regular';
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    font-size: 2rem;

  
    color: var(--color-brown);
}