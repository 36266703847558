.Container {
  display: flex;
  gap: 1rem;
  text-wrap: nowrap;
}

.Link {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--color-gray-900);
  text-decoration-color: var(--color-gray-400);
  text-decoration-thickness: 1px;
  text-decoration-line: none;
  text-underline-offset: 2px;

  @media (hover: hover) {
    &:hover {
      text-decoration-line: underline;
    }
  }

  &:focus-visible {
    border-radius: 0.125rem;
    outline: 2px solid var(--color-blue);
    text-decoration-line: none;
  }
}
