@import '/src/assets/css/common.scss';

.title_login {
  // font-family: 'LXGWWenKaiTC-Bold';
    font-family: 'Ma Shan Zheng';
  font-weight: 400;
  // font-size: 88px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  font-size: 6rem;
  
  color: var(--color-brown);
}


.sub_title {
  font-family: 'LXGWWenKaiTC-Regular';
  font-weight: 400;
  // font-size: 48px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  font-size: 3rem;
  
  color: var(--color-brown);
}

//------------------------------------------------------------------------------
.layout {
  // background: #f0f !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  height: 100vh;
  padding: 5%;
  gap: 2vh;
}

.layout_content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 90%;
}
.layout_timer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30%;
}

.layout_buttom { //FIXME: button BOTTOM ?
  // background: #0ff !important;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.layout_text{
  // background-color: chocolate;
  white-space: pre-line; 
  line-height:0.7;
  font-size: 1.8rem;
  color: var(--color-second-brown);
  text-align: center;
}

// Rectangle 1
.box {
  // background: #ff0 !important;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--color-gray-600);
  
  width: 90%;
  padding: 5%;
  gap: 2vh;
  
  background: rgba(243, 232, 220, 0.5);
}