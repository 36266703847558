
.layout {
  // background: #f00;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  width: 100%;
  height: 10vh;
  
  border-bottom: 1px solid var(--color-gray-600);
  // width: 378;
  // top: 105px;
  // left: 6px;
  // border-width: 1px;
}

.frame {
  // background: #0f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: 'LXGWWenKaiTC-Regular';
    // font-family: 'Ma Shan Zheng';
  font-weight: 800;
  // font-size: 88px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  font-size: 2.3rem;
  flex: 1;
  
  color: var(--color-brown);
}


.icon {
  color: var(--Schemes-On-Surface, rgba(29, 27, 32, 1));
}