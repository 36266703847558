.RadioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  color: var(--color-gray-900);
  
  //for BaseField
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  margin: 0;
  border: 1px solid var(--color-gray-200);
  height: 2.5rem;
  border-radius: 0.375rem;
  background: var(--Background-Default-Default, rgba(255, 255, 255, 1));
}

.Caption {
  font-weight: 500;
}

.Item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.Radio {
  box-sizing: border-box;
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  outline: 0;
  padding: 0;
  margin: 0;
  border: none;

  &[data-unchecked] {
    border: 1px solid var(--color-gray-300);
    background-color: transparent;
  }

  &[data-checked] {
    background-color: var(--color-gray-900);
  }

  &:focus-visible {
    outline: 2px solid var(--color-blue);
    outline-offset: 2px;
  }
}

.Indicator {
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-unchecked] {
    display: none;
  }

  &::before {
    content: '';
    border-radius: 100%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--color-gray-50);
  }
}
